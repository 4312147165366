import React from 'react';
import ReactDOM from 'react-dom';
import App from './components/App';
import GlobalStyle from './style/global';
import styled from 'styled-components';
import { DataProvider } from './components/DataContext';


const Wrapper = styled.div`
  width: 100%;
  height: 100%;
`;

ReactDOM.render(
  <DataProvider>
  <Wrapper>
    <GlobalStyle />
    
   
      <App />
   
  </Wrapper>
  </DataProvider>,
  document.getElementById('root')
);
