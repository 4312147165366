import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import BinahMonitor from './BinahMonitor';
import SettingsBars from './SettingsBars';
import { Flex } from './shared/Flex';
import { useCameras, useDisableZoom } from '../hooks';
import UAParser from 'ua-parser-js';
import { ToastContainer } from 'react-toastify';
import { useData } from './DataContext';
import Form from './Form';

const Container = styled(Flex) <{ isSettingsOpen: boolean }>`
  height: 100%;
  width: 100%;
  position: relative;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  background-color: ${({ isSettingsOpen }) =>
    isSettingsOpen ? 'rgba(0, 0, 0, 0.5)' : 'transparent'};
`;

const StyledToastContainer = styled(ToastContainer)`
  .Toastify__toast-container {
    width: 64px; /* Adjust the width to your desired size */
  }
  .Toastify__toast {
    height: 64px; /* Adjust the height to your desired size */
  }
  .custom-toast-container {
    margin-top:40px;
    display: flex;
    flex-direction: column;
    gap: 1rem; /* Adjust the gap as needed */
  }
`;

const App = () => {
  const cameras = useCameras();
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  const [cameraId, setCameraId] = useState<string>();
  const [isLicenseValid, setIsLicenseValid] = useState(false);
  const [isMobile] = useState(
    UAParser(navigator.userAgent).device.type === 'mobile',
  );
  useDisableZoom();

  const onSettingsClickedHandler = useCallback((event) => {
    const settingsBars = document.getElementById('settingsBars');
    const isSettingsButtonClicked = event.target.id === 'settingsButton';

    const isInsideSettingsClicked =
      settingsBars.contains(event.target as Node) || isSettingsButtonClicked;

    if (!isInsideSettingsClicked) {
      setIsSettingsOpen(false);
    }
  }, []);

  useEffect(() => {
    document.addEventListener('click', onSettingsClickedHandler);
    return () => {
      document.removeEventListener('click', onSettingsClickedHandler);
    };
  }, []);

  const updateLicenseStatus = useCallback((valid) => {
    setIsLicenseValid(valid);
  }, []);

  const toggleSettingsClick = useCallback(() => {
    setIsSettingsOpen(!isSettingsOpen);
  }, [isSettingsOpen]);

  const handleCloseSettings = useCallback(({ cameraId }) => {
    setCameraId(cameraId);
    setIsSettingsOpen(false);
  }, []);

  useEffect(() => {
    if (!cameras?.length) return;
    setCameraId(cameras[0].deviceId);
  }, [cameras]);

  const { state: DataState, dispatch: setData } = useData(); // Get 'data' and 'setData' from your context
  const { someProperty } = DataState;
  const user = JSON.parse(localStorage.getItem('user'));
  
  console.log(user, "kkk");

  // The logic here to update 'someProperty'


  return (
    <Container isSettingsOpen={isSettingsOpen}>
      {
        user?.gender && user?.weight && user?.height && user?.age ? <>   {someProperty ? (
          <StyledToastContainer position="top-center" />
        ) : (
          <>
            <BinahMonitor
              showMonitor={!(isMobile && isSettingsOpen)}
              cameraId={cameraId}
              onLicenseStatus={updateLicenseStatus}
              onSettingsClick={toggleSettingsClick}
              isSettingsOpen={isSettingsOpen}
            />
          </>
        )}

          <SettingsBars
            open={isSettingsOpen}
            onClose={handleCloseSettings}
            cameras={cameras}
            isLicenseValid={isLicenseValid}
          /></> :
          <Form />
      }



    </Container>
  );
};

export default App;
