import { createLocalStorageStateHook } from 'use-local-storage-state';


export const DEFAULT_MEASUREMENT_DURATION = 60;
export const MIN_MEASUREMENT_DURATION = 20;
export const MAX_MEASUREMENT_DURATION = 180;
   

 

// console.log("licenseKey:",process.env.REACT_APP_LICENSE_KEY,"productId:",process.env.REACT_APP_PRODUCT_ID)
export const useLicenseKey = createLocalStorageStateHook('licenseKey',process.env.REACT_APP_LICENSE_KEY);
export const useProductId = createLocalStorageStateHook('productId',process.env.REACT_APP_PRODUCT_ID);
export const useMeasurementDuration = createLocalStorageStateHook(
  'measurementDuration',
  DEFAULT_MEASUREMENT_DURATION,
);
