import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import StatsBox from './StatsBox';
import { Flex } from './shared/Flex';
import { isMobile } from '@binah/web-sdk';
import { toast } from 'react-toastify';
import { useData } from './DataContext';
import axios from 'axios';

const Wrapper = styled(Flex)`
  display: flex;
  position: absolute;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 80px;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 3px;
  padding: 13px 50px;
  bottom: 30px;
  box-sizing: border-box;
`;

const BoxesWrapper = styled(Flex)`
  gap: 30px;
`;

interface VitalSign<T> {
  value: T;
  isEnabled: boolean;
}

interface IStats {
  vitalSigns: {
    heartRate: VitalSign<number>;
    breathingRate: VitalSign<number>;
    stress: VitalSign<Stress>;
    sdnn: VitalSign<number>;
    spo2: VitalSign<number>;
    prq: VitalSign<number>;
    hemoglobinA1c: VitalSign<number>;
    hemoglobin: VitalSign<number>;
    bloodPressure: VitalSign<BloodPressureValue>;
    wellness: VitalSign<WellnessLevel>;
  };
}

export enum Stress {
  UNKNOWN,
  LOW,
  NORMAL,
  MILD,
  HIGH,
  EXTREME,
}

export enum WellnessLevel {
  UNKNOWN,
  LOW,
  NORMAL,
  HIGH,
}

export type BloodPressureValue = {
  systolic: number;
  diastolic: number;
};

interface Payload {
  heartRate: number;
  breathingRate: number;
  stress: Stress;
  hrvSdnn: number;
  spo2: number;
  bloodPressureToDisplay: string;
  prq: number;
  hemoglobinA1c: number;
  hemoglobin: number;
  wellness: WellnessLevel;
}

const Stats = ({ vitalSigns }: IStats) => {
  const [id, setId] = useState<string | null>(null);
  const [message, setMessage] = useState<string>('');
  const [campWebsite, setCampWebsite] = useState<boolean>(false);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const idParam = urlParams.get('id');
    const campIdParam = urlParams.get('camp_id');
    if (idParam) {
      setId(idParam);
      setCampWebsite(false);
    } else if (campIdParam) {
      setId(campIdParam);
      setCampWebsite(true);
    }
  }, []);

  const bloodPressureToDisplay =
    vitalSigns.bloodPressure.value?.systolic && vitalSigns.bloodPressure.value?.diastolic
      ? `${vitalSigns.bloodPressure.value.systolic}/${vitalSigns.bloodPressure.value.diastolic}`
      : '--';

  const [payload, setPayload] = useState<Payload>({
    heartRate: 0,
    breathingRate: 0,
    stress: Stress.LOW,
    hrvSdnn: 0,
    spo2: 0,
    hemoglobinA1c: 0,
    prq: 0,
    hemoglobin: 0,
    bloodPressureToDisplay: "",
    wellness: WellnessLevel.LOW,
  });

  const lastChangedValues = useRef<Payload>({
    heartRate: 0,
    breathingRate: 0,
    stress: Stress.LOW,
    hrvSdnn: 0,
    spo2: 0,
    hemoglobinA1c: 0,
    prq: 0,
    hemoglobin: 0,
    bloodPressureToDisplay: "",
    wellness: WellnessLevel.LOW,
  });

  useEffect(() => {
    lastChangedValues.current = {
      heartRate: vitalSigns.heartRate.isEnabled ? (vitalSigns.heartRate.value || 0) : 0,
      breathingRate: vitalSigns.breathingRate.isEnabled ? (vitalSigns.breathingRate.value || 0) : 0,
      stress: vitalSigns.stress.isEnabled ? (vitalSigns.stress.value || Stress.LOW) : Stress.LOW,
      hrvSdnn: vitalSigns.sdnn.isEnabled ? (vitalSigns.sdnn.value || 0) : 0,
      spo2: vitalSigns.spo2.isEnabled ? (vitalSigns.spo2.value || 0) : 0,
      hemoglobinA1c: vitalSigns.hemoglobinA1c.isEnabled ? (vitalSigns.hemoglobinA1c.value || 0) : 0,
      hemoglobin: vitalSigns.hemoglobin.isEnabled ? (vitalSigns.hemoglobin.value || 0) : 0,
      prq: vitalSigns.prq.isEnabled ? (vitalSigns.prq.value || 0) : 0,
      bloodPressureToDisplay: bloodPressureToDisplay,
      wellness: vitalSigns.wellness.isEnabled ? (vitalSigns.wellness.value || WellnessLevel.LOW) : WellnessLevel.LOW,
    };

    setPayload(lastChangedValues.current);
  }, [
    vitalSigns.heartRate.value,
    vitalSigns.breathingRate.value,
    vitalSigns.stress.value,
    vitalSigns.sdnn.value,
    vitalSigns.spo2.value,
    vitalSigns.hemoglobinA1c.value,
    vitalSigns.hemoglobin.value,
    vitalSigns.prq.value,
    vitalSigns.wellness.value,
    bloodPressureToDisplay,
  ]);

  const { state, dispatch } = useData();

  useEffect(() => {
    const sendResults = async () => {
      try {
        let results = null;
        if (payload.breathingRate > 0 && payload.heartRate > 0 && payload.bloodPressureToDisplay && payload.bloodPressureToDisplay !== '--') {
          localStorage.removeItem('user');
          if (campWebsite) {
            results = await axios.post(
              `${process.env.React_APP_MEDICAL_CAMP_URL}/patients/binar_results/${id}`, {
              data: {
                heartRate: payload.heartRate.toString(),
                breathingRate: payload.breathingRate.toString(),
                stress: payload.stress.toString(),
                hrvSdnn: payload.hrvSdnn.toString(),
                spo2: payload.spo2.toString(),
                hemoglobinA1c: payload.hemoglobinA1c.toString(),
                prq: payload.prq.toString(),
                hemoglobin: payload.hemoglobin.toString(),
                wellness: payload.wellness.toString(),
                bloodPressureToDisplay: payload.bloodPressureToDisplay,
              }
            }
            );
          } else {
            results = await axios.post(
              `${process.env.REACT_APP_APIS_URL}/zuri_binah/send_result/${id}`, {
              heartRate: payload.heartRate.toString(),
              breathingRate: payload.breathingRate.toString(),
              stress: payload.stress.toString(),
              hrvSdnn: payload.hrvSdnn.toString(),
              spo2: payload.spo2.toString(),
              hemoglobinA1c: payload.hemoglobinA1c.toString(),
              prq: payload.prq.toString(),
              hemoglobin: payload.hemoglobin.toString(),
              wellness: payload.wellness.toString(),
              bloodPressureToDisplay: payload.bloodPressureToDisplay,
            }
            );
          }

          if (results?.data?.message?.includes("Binah Results Saved Successfully")) {
            setMessage("Data saved successfully. Reload the page to test another patient.");
          }
        }
        console.log("results", results?.data?.message);
      } catch (error) {
        console.error("Error sending results:", error);
      }
    };

    sendResults();
  }, [payload]);

  return (
    <Wrapper>
      <BoxesWrapper>
        {message ? (
          <p className='flex justify-center w-full text-[#5BDADD]'>{message}</p>
        ) : (
          <>
            <StatsBox
              title={'HR'}
              value={vitalSigns.heartRate.isEnabled ? vitalSigns.heartRate.value || '--' : 'N/A'}
            />
            <StatsBox
              title={'BR'}
              value={vitalSigns.breathingRate.isEnabled ? vitalSigns.breathingRate.value || '--' : 'N/A'}
            />
            <StatsBox
              title={'SL'}
              value={vitalSigns.stress.isEnabled ? vitalSigns.stress.value || '--' : 'N/A'}
            />
            <StatsBox
              title={'SDNN'}
              value={vitalSigns.sdnn.isEnabled ? vitalSigns.sdnn.value || '--' : 'N/A'}
            />
            <StatsBox
              title={'PRQ'}
              value={vitalSigns.prq.isEnabled ? vitalSigns.prq.value || '--' : 'N/A'}
            />
            <StatsBox
              title={'SPO'}
              value={vitalSigns.spo2.isEnabled ? vitalSigns.spo2.value || '--' : 'N/A'}
            />
            <StatsBox
              title={'HB1AC'}
              value={vitalSigns.hemoglobinA1c.isEnabled ? vitalSigns.hemoglobinA1c.value || '--' : 'N/A'}
            />
            <StatsBox
              title={'oxy'}
              value={vitalSigns.hemoglobin.isEnabled ? vitalSigns.hemoglobin.value || '--' : 'N/A'}
            />
            <StatsBox
              title={'well'}
              value={vitalSigns.wellness.isEnabled ? vitalSigns.wellness.value || '--' : 'N/A'}
            />
          </>
        )}
        {isMobile() && (
          message ? (
            <p className='flex justify-center w-full text-[#5BDADD]'>{message}</p>
          ) : (
            <StatsBox
              title={'BP'}
              value={vitalSigns.bloodPressure.isEnabled ? bloodPressureToDisplay : 'N/A'}
            />
          )
        )}
      </BoxesWrapper>
    </Wrapper>
  );
};

export default Stats;
