import React, { ReactHTMLElement, useState } from 'react';
import styled from 'styled-components';
import media from '../style/media';

const FormContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    height: 100%;
    width: 100%;
    padding: 20px;
    
   
`;

const FormWrapper = styled.form`
  display: flex;

    width: 70%;
  flex-direction: column;
  margin-bottom: 20px;
`;

const Label = styled.label`
  margin-bottom: 5px;
  font-weight: bold;
`;

const Input = styled.input`
  padding: 8px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
 
  border-radius: 4px;
  font-size: 16px;
`;
const Button = styled.button`
  padding: 8px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  background-color: #4CAF50;
  color: white;
  cursor: pointer;
  &:hover{
    background-color: #3e8e41;
  }
`;
const Select = styled.select`
  padding: 8px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
`;
const Error = styled.p`
  color: red;
  font-size: 14px;
  margin-bottom: 5px;
`;

const Form = () => {
    const [gender, setGender] = useState('MALE');
    const [weight, setWeight] = useState(null);
    const [height, setHeight] = useState(null);
    const [age, setAge] = useState(null);
    const [weightError, setWeightError] = useState(false);
    const [heightError, setHeightError] = useState(false);
    const [ageError, setAgeError] = useState(false);

    const handleSubmit = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        console.log({ gender, weight, height, age });

        if (weight < 40 || weight > 200) {
            setWeightError(true);
        } else if (height < 130 || height > 230) {
            setHeightError(true);
        } else if (age < 18 || age > 110) {
            setAgeError(true);
        } else {
            localStorage.setItem('user', JSON.stringify({ gender, weight, height, age }));
            window.location.reload();
        }




    }

    return (
        <FormContainer>
            <FormWrapper >
                <Label>Gender</Label>
                <Select value={gender} onChange={(e) => setGender(e.target.value)}>
                    <option value="MALE">Male</option>
                    <option value="FEMALE">Female</option>
                </Select>

                <Label>Weight (kg)</Label>
                <Input
                    type="number"
                    min={40}
                    max={200}
                    value={weight}
                    onChange={(e) => setWeight(Number(e.target.value))}
                />
                <Error>{weightError && 'Weight should be between 40kg and 200kg'}</Error>
                <Label>Height (cm)</Label>
                <Input
                    type="number"
                    value={height}
                    min={130}
                    max={230}
                    onChange={(e) => setHeight(Number(e.target.value))}
                />
                <Error>{heightError && 'Height should be between 130cm and 230cm'}</Error>
                <Label>Age</Label>
                <Input
                    type="number"
                    value={age}
                    min={18}
                    max={110}
                    onChange={(e) => setAge(Number(e.target.value))}
                />
                <Error>{ageError && 'Age should be between 18 and 110'}</Error>

                <Button onClick={(e: React.MouseEvent<HTMLButtonElement>) => handleSubmit(e)} type="submit">Submit</Button>
            </FormWrapper>
        </FormContainer>
    );
};
export default Form;