import React, { createContext, useContext, useReducer, ReactNode } from 'react';

// Define your data type
export interface DataState {
  someProperty: boolean;
  // Add other properties as needed
}

// Define the initial state
const initialState: DataState = {
  someProperty: false,
  // Initialize other properties here
};

// Define the actions
type Action = { type: 'SET_SOME_PROPERTY'; payload: boolean };
// Add other action types as needed

// Create a context for the data
interface DataContextType {
  
  state: DataState;
  dispatch: React.Dispatch<Action>;
}

const DataContext = createContext<DataContextType | undefined>(undefined);

// Define a reducer function
const dataReducer = (state: DataState, action: Action): DataState => {
  switch (action.type) {
    case 'SET_SOME_PROPERTY':
      return { ...state, someProperty: action.payload };
    // Handle other action types here
    default:
      return state;
  }
};

// Define a DataProvider component to wrap your app with
interface DataProviderProps {
  children: ReactNode;
}

export const DataProvider: React.FC<DataProviderProps> = ({ children }) => {
  const [state, dispatch] = useReducer(dataReducer, initialState);

  return <DataContext.Provider value={{ state, dispatch }}>{children}</DataContext.Provider>;
};

// Define a custom hook for accessing the data context
export const useData = () => {
  const context = useContext(DataContext);
  if (context === undefined) {
    throw new Error('useData must be used within a DataProvider');
  }
  return context;
};
